@import '~@astrid/components/src/scss/utils';

.flying-stars {
  position: relative;

  &__main-star {
    fill: $maize;
    &--pulse {
      @extend .flying-stars__main-star;
      animation: 700ms pulse 3;
    }
  }

  &__star {
    position: absolute;
    fill: $maize;
    z-index: 1000;
    transition: all 1s;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
    opacity: 0.7;
  }
}
