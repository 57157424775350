@import '~@astrid/components/src/scss/utils';

.jump-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 36px 0 64px;
  width: 100%;

  @include breakpoint(xs) {
    margin: 16px 0 48px;
  }

  &__wrapper {
    position: relative;

    @include breakpoint(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    @include breakpoint(xs) {
      font-size: 2.2rem;
    }
  }

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Lexend';
    margin-right: auto;
    margin-left: auto;

    img {
      width: 64px;
      height: 48px;
      border-radius: 10px;
      margin-right: 12px;
      object-fit: cover;
    }

    svg {
      width: 35px;
      height: 35px;
      margin-left: 16px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      align-items: flex-start;
      text-align: left;

      span {
        font-size: 14px;
        color: $black-30;
      }
    }
  }

  &__character {
    width: 250px;
    height: auto;
    position: absolute;
    top: 0;
    left: -250px;

    @include breakpoint(sm) {
      position: static;
    }

    @include breakpoint(xs) {
      display: none;
    }
  }
}
