@import '~@astrid/components/src/scss/utils';

.mobile-app-download {
  width: 100%;
  min-height: 100%;
  background: linear-gradient(180deg, rgba(255, 190, 168, 0.6) 1.83%, rgba(0, 21, 147, 0.6) 113.59%);
  position: absolute;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;

  @include breakpoint(xs) {
    & h1 {
      font-size: 30px;
      line-height: 1.3;
    }

    & p {
      font-size: 18px;
      line-height: 1.3;
    }
  }

  &__title {
    font-size: 3.5rem;
    line-height: 1.2;
    max-width: 800px;
    color: white;
    margin: 0 0 1rem 0;
    text-align: center;
  }

  &__container {
    height: 100%;
    max-width: 1090px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }

  &__qrcode {
    border: 6px solid white;
    border-radius: 8px;
    width: calc(30vh + 12px);
    height: calc(30vh + 12px);
    max-width: 300px;
    max-height: 300px;
    svg {
      width: 30vh;
      height: 30vh;
      max-width: 288px;
      max-height: 288px;
    }

    @include breakpoint(sm) {
      display: none;
    }
  }

  &__upper-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 50px;

    & > img {
      max-width: 400px;
      max-height: 37vh;
      flex: 1;
      margin-left: 100px;
      @include breakpoint(sm) {
        margin: 0;
      }
    }

    @include breakpoint(sm) {
      align-items: center;
      flex-direction: column;
      margin: 0;
    }
  }

  &__text {
    color: white;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.25;
    font-weight: bold;
    max-width: 780px;
    @include breakpoint(sm) {
      margin-bottom: 20px;
    }
  }

  &__store-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include breakpoint(sm) {
      flex-direction: column;
    }
  }

  &__badge {
    margin: 0 8px;
    height: 70px;
    width: auto;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);

    @include breakpoint(sm) {
      margin: 4px 0;
      height: 60px;
    }

    @include breakpoint(xs) {
      height: 50px;
    }
  }

  &__styling-element {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 9vh;
    max-width: 100%;
    max-height: 100%;

    @include breakpoint(sm) {
      bottom: 2vh;
    }

    @include breakpoint(xs) {
      bottom: 0;
    }
  }
}
