@import '~@astrid/components/src/scss/utils';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: white;
  border-bottom: 2px solid #f2f3f4;
  position: sticky;
  top: 0;
  z-index: 1000;
  flex: 0 0 auto;

  &__container {
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    @include breakpoint(xs) {
      padding: 10px 16px;
    }
  }

  &__logo {
    height: 23px;
    width: 90px;
    cursor: pointer;
  }

  &__user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint(xs) {
      display: none;
    }
  }

  &__close {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.25rem;
    fill: $cobalt-blue;
    cursor: pointer;
    border-radius: 100px;
    transition: 250ms all;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__menu-icon {
    display: none;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.25rem;
    fill: $cobalt-blue;
    cursor: pointer;
    border-radius: 100px;
    transition: 250ms all;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    @include breakpoint(xs) {
      display: block;
    }
  }

  &__profile-icon {
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    fill: $cobalt-blue;
  }

  &__achievement-icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  &__badge-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 3rem;
    padding: 0.2rem 0.75rem;
    border-radius: 16px;
    background-color: $blue-grey;
    margin-left: 1rem;
    position: relative;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include breakpoint(xs) {
      margin: 0;
    }
  }

  &__progress-bar-container {
    position: absolute;
    bottom: 0;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

.collapsable-bar {
  display: flex;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  bottom: -60px;
  left: 0;
  width: 100%;
  padding: 10px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
  transition: all 250ms;
  z-index: 1000;

  &--open {
    @extend .collapsable-bar;
    @include breakpoint(xs) {
      visibility: visible;
      opacity: 1;
    }
  }
}
