@import '~@astrid/components/src/scss/utils';

.level-up-carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 260px;
  @include breakpoint(xs) {
    flex-direction: column;
    height: auto;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1;
    height: 100%;
    background-color: slategray;
    color: white;
    cursor: pointer;
    margin: 8px;
    border-radius: 10px;
    box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
    transition: 230ms all;
    user-select: none;
    background: #eee no-repeat;
    background-size: cover;
    background-position: center;

    & > h4 {
      margin: 4px 0 16px 16px;
      color: white;
    }

    & > span {
      margin: 0 0 0 16px;
      color: white;
    }

    @include breakpoint(xs) {
      flex: 1 0 auto;
      height: 180px;
      width: 100%;
    }
  }
}
