@import '~@astrid/components/src/scss/utils';

.profile {
  display: flex;
  flex-direction: column;
  max-width: 970px;
  background-color: $very-light-pink;
  padding: 40px 32px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
  align-self: center;
  margin: 32px 8px;
  flex: 1 1 auto;
  max-height: calc(100% - 146px);
  width: 100%;

  @include breakpoint(xs) {
    padding: 24px 16px;
    margin: 8px 8px;
    max-height: 700px;
    width: calc(100% - 16px);
    flex: 1 0 auto;
  }

  &__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    padding: 0 4px 16px;
    width: 100%;
    flex: 1 0 auto;

    @include breakpoint(xs) {
      flex-direction: column;
    }

    button {
      align-self: flex-start;
      float: right;
    }

    &-display {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 24px;
      @include breakpoint(xs) {
        align-self: start;
        margin: 0 0 12px 0;
      }
    }

    &-avatar {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 100px;
      margin-left: 16px;
      @include breakpoint(xs) {
        margin-left: 8px;
        width: 80px;
        height: 80px;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      h3 {
        margin: 0;
      }

      p {
        margin: 12px 0;
        color: $black-76;
      }
    }
  }
}
