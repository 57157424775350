@import '~@astrid/components/src/scss/utils';

.container {
  display: flex;
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $blue-grey;

  p {
    margin-top: 0;
  }
}

.character {
  width: 350px;
  height: auto;
}

.continue {
  width: 300px;
  svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }
}
