@import '~@astrid/components/src/scss/utils';

.exercise-progress-bar {
  position: relative;
  width: 100%;
  height: 7px;
  background-color: lighten($cobalt-blue, 60%);

  &__progress {
    position: absolute;
    background-color: $cobalt-blue;
    top: 0;
    left: 0;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: width 1000ms ease;
  }
}
