@import '~@astrid/components/src/scss/utils';

.edit-profile {
  display: flex;
  flex-direction: row;
  max-width: 970px;
  background-color: $very-light-pink;
  justify-items: flex-start;
  padding: 40px 32px;
  border-radius: 10px;
  box-shadow: inset 0 -4px 0 0 rgba(0, 0, 0, 0.25);
  align-self: center;
  margin: 32px 8px;
  flex: 1 1 auto;
  width: 100%;

  @include breakpoint(xs) {
    padding: 24px 16px;
    margin: 8px 8px;
    max-height: none;
    width: auto;
    flex: 1 0 auto;
    flex-direction: column;
  }

  &--left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include breakpoint(xs) {
      flex-direction: row;
    }
  }

  &__upload {
    margin-right: 70px;
    @include breakpoint(xs) {
      margin-right: 0;
      margin-bottom: 20px;
      width: 80px;
      height: 80px;
      align-self: center;

      label {
        width: 80px;
        height: 80px;
      }

      img {
        width: 80px;
        height: 80px;
      }
    }
  }

  &__avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 70px;
    @include breakpoint(xs) {
      margin-right: 20px;
      width: 80px;
      height: 80px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    & > h3 {
      margin: 0 0 8px;
    }

    & > h4 {
      margin-bottom: 16px;
    }
  }

  &__security {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: fit-content;
    cursor: pointer;

    h4 {
      margin: 0;
    }

    svg {
      transition: 230ms all;
    }

    &-info {
      width: 100%;
      border-bottom: 1px solid rgba(black, 0.1);
      text-align: center;
      transform-origin: top;
      animation: expand 230ms;
      p {
        margin: 10px 0;
      }
    }
  }

  &__logout {
    width: fit-content;
    height: fit-content;
    margin-top: 18px;
    @include breakpoint(xs) {
      margin-top: 0;
    }
  }
}

@keyframes expand {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}
