@import '~@astrid/components/src/scss/utils';

.dashboard {
  width: 100%;
  padding: 0 20px 0;
  max-width: 1440px;
  margin: 0 auto;

  @include breakpoint(lg) {
    padding: 0 40px;
  }

  @include breakpoint(xs) {
    padding: 0 20px;
  }

  &__book-carousel {
    margin-bottom: 64px;
  }

  &__level-up {
    margin-bottom: 64px;

    @include breakpoint(xs) {
      margin-bottom: 32px;
    }
  }
}
