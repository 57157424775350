@import '~@astrid/components/src/scss/utils';

.root {
  display: block;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
  max-width: 100%;
  padding: 0 15px;

  h1 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 100%;
    align-items: center;
    > button {
      margin-top: 20px;
      min-width: 154px;
    }
  }
}

.logo {
  position: absolute;
  height: 30px;
  width: 118px;
  left: 50%;
  transform: translate(-50%);
  top: 16px;
}

.leftGraphic {
  position: absolute;
  bottom: 56px;
  width: 550px;
  left: -66px;
  z-index: -1;
  @include breakpoint(sm) {
    display: none;
  }
}

.rightGraphic {
  position: absolute;
  right: 80px;
  bottom: 56px;
  width: 550px;
  z-index: -1;
  @include breakpoint(sm) {
    right: auto;
    left: auto;
    width: 80%;
    bottom: 20px;
  }
}
