@import '~@astrid/components/src/scss/utils';

.container {
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  border-bottom: 1px solid rgba(black, 0.1);
  width: 100%;
  flex-wrap: wrap;

  & > p {
    width: 220px;
    margin: 10px 0;

    @include breakpoint(xs) {
      width: 170px;
    }
  }

  & > span {
    color: $text-secondary;
  }
}

.customInput {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 3px;

  &[data-invalid='true'] {
    input {
      border: 1px solid red;
    }
  }

  &:hover {
    background-color: rgba(black, 0.1);
  }

  & > svg {
    position: absolute;
    right: 5px;
  }

  & > span {
    color: red;
    margin: 0 50px 0 6px;
    font-size: 12px;
  }

  & > input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    padding: 6px 4px;
    border-radius: 3px;
    &:focus {
      background-color: rgba(black, 0.1);
    }
  }
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
  button {
    margin: 0 4px;
  }
}
