@import '~@astrid/components/src/scss/utils';

.congratulations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  flex: 1 0 auto;
  background-color: $blue-grey;
  text-align: center;

  @include breakpoint(sm) {
    padding: 0 10px 10px;
  }

  &__info {
    @include breakpoint(xs) {
      display: none;
    }
  }

  &__progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

    @include breakpoint(xs) {
      margin: 20px 0 10px;
    }

    &-wrapper {
      margin: 0 32px;
      h3 {
        margin: 16px 0;
      }
      @include breakpoint(xs) {
        margin: 0 10px;
        h3 {
          margin: 12px 0;
        }
      }
    }
  }

  &__level {
    width: 64px;
    height: 64px;
    fill: $seafoam-blue;
  }

  &__level-up {
    animation: 1s pulse normal;
  }

  &__timer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background: transparent;
    & > div {
      height: 100%;
      animation: animated-width 10s linear normal forwards;
      background-color: $cobalt-blue;
    }
  }

  &__rate {
    p {
      margin-bottom: 8px;
    }
    margin-right: 40px;
    @include breakpoint(xs) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include breakpoint(xs) {
      flex-direction: column;
    }
  }
}

@keyframes animated-width {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
