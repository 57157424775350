@import '~@astrid/components/src/scss/utils';

.create-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;

  @include breakpoint(sm) {
    flex: 1 0 auto;
    padding: 8px;
  }

  &__heading {
    text-align: center;
    @include breakpoint(sm) {
      margin: 8px 0;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    // flex: 1 0 auto;

    button {
      margin-top: 16px;
    }

    div[data-element-role='wrapper'] {
      display: flex;
      flex-direction: row;
      width: 100%;

      @include breakpoint(xs) {
        flex-direction: column;
      }
    }
  }
}
