@import '~@astrid/components/src/scss/utils';

.container {
  background: $blue-grey;
  padding: 0 20px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-y: scroll;
}

.footer {
  height: 110px;
  text-align: center;
  overflow: hidden;
  padding-top: 24px;
}

.header {
  height: 70px;
  padding: 20px 0;

  & svg {
    height: 100%;
    width: auto;
  }
}

.content {
  align-items: center;
  text-align: center;
  margin: 0 auto;
  min-height: calc(100vh - 180px);
}

.heading {
  margin-bottom: 1rem;
}

.footerText {
  margin: 0;
  font-size: 0.9rem;
}

.email {
  font-weight: bold;
}

.questions {
  display: flex;
  gap: 4px;
  gap: 8px;
  justify-content: center;
  margin: 0;
}

.successText {
  max-width: 22rem;
  margin: 0 auto;
}

.screenshot {
  height: 100%;
  max-width: 400px;
  width: 75vw;
}

.abbe {
  height: 100%;
  max-width: 240px;
  width: 50vw;
}

.button {
  width: 20rem;
  margin: 0 auto;
}
