@import '~@astrid/components/src/scss/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  height: 100%;
  text-align: center;

  @include breakpoint(xs) {
    padding-top: 0;
    justify-content: center;
  }

  p {
    margin: 0 0 32px;
  }

  img {
    width: 200px;
  }
}
