.app {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#draggable {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
