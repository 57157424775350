@import '~@astrid/components/src/scss/utils';

.login {
  &__sign-in-link {
    margin-bottom: 6px;
  }
  &__forgot {
    cursor: pointer;
    margin: 0;
  }
}
