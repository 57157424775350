@import '~@astrid/components/src/scss/utils';

.level {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &__text {
    font-size: 18px;
    margin-left: 6px;

    &--disapearing {
      margin-right: 5px;
      @include breakpoint(xs) {
        display: none;
      }
    }
  }
}
