@import '~@astrid/components/src/scss/utils';

.global-score {
  display: flex;
  align-items: center;
  height: 26px;

  svg {
    fill: $maize;
    width: 24px;
    height: 24px;
  }

  &__score {
    font-size: 18px;
    margin-left: 6px;
    margin-top: 2px;
    color: black;
  }
}
