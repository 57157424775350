@import '~@astrid/components/src/scss/utils';

.book-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Lexend';
  margin-right: auto;
  margin-left: auto;

  img {
    width: 64px;
    height: 48px;
    border-radius: 10px;
    margin-right: 12px;
    object-fit: cover;
  }

  & > svg {
    width: 35px;
    height: 35px;
    margin-left: 14px;
    fill: black !important;
  }

  & > div {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    color: black;
    max-width: 260px;

    span {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin: 2px 0 0 0;
      padding: 0;
      font-size: 14px;
      color: rgba(black, 0.5);
    }
  }
}
