@import '~@astrid/components/src/scss/utils';

.rewards {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-height: 0;

  & > h2 {
    margin: 16px 0 10px;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
  }
}
.achievement {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 18px;
  margin: 8px;
  width: 280px;
  color: $black-76;

  & > div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  &-name {
    font-size: 20px;
  }

  &-description {
    font-size: 16px;
  }
}
